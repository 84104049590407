<template>
  <v-container fluid class="fluid">
    <h1 class="text-left product-title" v-html="$t('contract_new')"></h1>
    <!--     Vertragsinfos Form-->
    <v-form ref="createContractForm" id="createContractForm">
      <v-row>
        <v-col xs="12" md="6" xl="6" class="custom-readonly-text-field">
          <h2 v-html="$t('contract_new_informations')"></h2>
          <!-- Erstmilchkäufer TSM -->
          <dbm-extended-local-unit-picker
            v-if="$privileges.has({ path: '/dairies', permission: 'search' })"
            v-model="contractForm.dairy.id"
            :label="$t('contract_new_erstmilch_buyer')"
            :rules="[$rules.required]"
            :cards="['searchLocalUnit', 'searchContact', 'searchDairy']"
          />

          <!-- Erstmilchkäufer Verwerter -->
          <div
            v-if="
              privileges.has({
                path: '/dairies',
                permission: 'searchManaged'
              }) && !$privileges.has({ path: '/dairies', permission: 'search' })
            "
          >
            <v-alert type="warning" v-if="dairies.length === 0"><span v-html="$t('contract_no_active_dairy_found')" /></v-alert>
            <br />

            <v-select
              id="contract_new_erstmilch_buyer_for_dairies"
              v-model="contractForm.dairy.id"
              :label="$t('contract_new_erstmilch_buyer')"
              :items="dairies"
              item-title="name"
              item-value="id"
              :rules="[rules.required]"
            />
          </div>
          <!-- Lieferant -->
          <dbm-extended-local-unit-picker v-model="contractForm.farm.id" :label="$t('contract_new_lieferant')" :rules="[$rules.required]" />
          <!--    Vertrag von-->
          <dbmDatePicker v-model="contractForm.validFrom" :dateLabel="$t('contract_new_valid_from')" :required="true"></dbmDatePicker>
          <!--    Vertrag bis-->
          <dbmDatePicker v-model="contractForm.validUntil" :dateLabel="$t('contract_new_valid_until')" :clearable="true"></dbmDatePicker>
          <!--    Tierart-->
          <v-select
            id="contract_new_animal_type"
            v-model="contractForm.animalType.id"
            :label="$t('contract_new_animal_type')"
            :items="animalTypes"
            :item-title="this.$getLangKey()"
            item-value="id"
            clearable
            :rules="[rules.required]"
            :loading="loadingDefault"
            @change="loadDefaultEncashmentOrganisation"
          />
          <!-- Lieferanten-Nr -->
          <v-text-field id="contract_organisation" v-model="contractForm.numberAspect" type="number" :label="$t('contract_new_number_aspect_value')" />
          <!-- Inkassostelle -->
          <v-select
            id="contract_new_encashment_organisation"
            v-model="contractForm.collectionAspect"
            :label="$t('contract_new_encashment_organisation')"
            :items="activeProductionEncashmentOrganisations"
            item-title="name"
            item-value="id"
            :loading="this.loadingActiveProductionEncashmentOrganisations"
          />
        </v-col>
        <!--    Datenschutzerklärung-->
        <v-col xs="12" md="6" xl="6">
          <h2 v-html="$t('contract_new_dataprotection')"></h2>
          <p v-html="$t('contract_new_data_protection_long')"></p>
        </v-col>
      </v-row>
      <div class="row search spacer-md"></div>
      <v-btn class="m-2" color="primary" @click="createContract" :loading="loading">
        <span v-html="$t('contract_new_save')" />
      </v-btn>
      <v-btn class="m-2" color="secondary" @click="reset">
        <span v-html="$t('contract_new_back')" />
      </v-btn>
    </v-form>
    <v-alert v-model="showErrorMessage" class="wrong-file-type-alert" color="red" type="error" density="compact" dismissible>
      {{ errorMessage }}
    </v-alert>
  </v-container>
</template>

<script lang="ts">
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { showError } from '@/services/axios'
import { apiURL, emitter } from '@/main'
import _ from 'lodash'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
const legalEntityStore = useLegalEntityStore()
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import DbmExtendedLocalUnitPicker from '@/components/pickers/dbmExtendedLocalUnitPicker.vue'
import { useOrganisationsStore } from '@/store/enums/OrganisationsStore'

import { defineComponent } from 'vue'
import { privileges } from '@/services/privileges'
export default defineComponent({
  name: 'contracts_create',
  components: { DbmExtendedLocalUnitPicker, DbmDatePicker: DbmDatePicker },
  data() {
    return {
      contractForm: {
        contractualAspect: 'CONTRACT',
        animalType: { id: '' },
        farm: { id: null },
        dairy: { id: null },
        validFrom: '',
        collectionAspect: 0
      },
      showErrorMessage: false,
      errorMessage: undefined,
      dialogdairy: false,
      dialogfarms: false,
      loading: false,
      loadingDefault: false,
      privileges: privileges,
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      },
      dairies: []
    }
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    activeProductionEncashmentOrganisations() {
      return useOrganisationsStore().activeproductionEncashmentOrganisations
    },
    loadingActiveProductionEncashmentOrganisations() {
      return useOrganisationsStore().loadingactiveproductionEncashmentOrganisations
    }
  },
  methods: {
    async createContract(e) {
      this.showErrorMessage = false
      if (!(await this.$refs.createContractForm.validate()).valid) {
        return false
      }
      this.loading = true
      const form = _.omit(this.contractForm, ['farm.legalEntityName1', 'dairy.legalEntityName1', 'collectionAspect', 'numberAspect'])
      try {
        const response = await this.axios.post(
          apiURL + '/contracts',
          {
            ...form,
            contractualAspect: 'CONTRACT'
          },
          {
            headers: { 'Content-Type': 'application/json' },
            params: {
              numberAspectValue: this.contractForm.numberAspect,
              collectionAspectValue: this.contractForm.collectionAspect
            }
          }
        )
        if ((await response.status) === 200) {
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          await this.reset()
        }
      } catch (e) {
        this.showErrorMessage = true
        this.errorMessage = showError(e, 'inline')
      } finally {
        this.loading = false
      }
    },
    closeDialog(type) {
      type === 'dairy' ? (this.dialogdairy = false) : (this.dialogfarms = false)
    },
    async reset() {
      this.goToContracts()
      this.$refs.createContractForm.reset()
    },
    goToContracts() {
      this.$router
        .push({
          name: 'contracts_read'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    async loadDefaultEncashmentOrganisation() {
      this.loadingDefault = true
      try {
        const result = await this.axios.get(apiURL + '/contracts/defaults/collection', {
          params: {
            animalTypeId: this.contractForm.animalType.id
          }
        })
        if (await result) {
          this.contractForm.collectionAspect = result.data
        }
      } catch (e) {
        showError(e)
      } finally {
        this.loadingDefault = false
      }
    },
    async loadDairies() {
      let url = apiURL + '/legalEntities/' + legalEntityStore.getSelectedPersonId + '/dairies'
      try {
        const response = await this.axios.get(url)
        this.dairies = response.data
        if (this.dairies.length == 1) {
          this.contractForm.dairy.id = this.dairies[0].id
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  beforeMount() {
    useOrganisationsStore().fetchActiveproductionEncashmentOrganisations()
    useAnimalTypesStore().fetchAnimalTypes()
  },
  mounted() {
    if (!privileges.has({ path: '/dairies', permission: 'search' })) {
      this.loadDairies()
    }
  }
})
</script>
